import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {LoginComponent} from './login/login.component';
import {AdminModule} from './admin/admin.module';
import {BeneficiarioModule} from './beneficiario/beneficiario.module';
import {PublicoModule} from './publico/publico.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {NgxSpinnerModule} from 'ngx-spinner';
import {AuthService} from './auth.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {Interceptor} from './interceptor';
import localeEsBo from '@angular/common/locales/es-BO';
import {registerLocaleData} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {PoliticasPrivacidadComponent} from './politicas-privacidad/politicas-privacidad.component';

registerLocaleData(localeEsBo, 'es-Bo');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PoliticasPrivacidadComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    AdminModule,
    BeneficiarioModule,
    NgxSpinnerModule,
    PublicoModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [AuthService, {
    provide: LOCALE_ID, useValue: 'es-Bo'
  }, {
    provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
