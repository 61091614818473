import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import {faEnvelope, faHome, faMapMarkerAlt, faPaperPlane, faPhoneAlt} from '@fortawesome/free-solid-svg-icons';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../auth.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  places = [
    {
      lat : -17.998707,
      lng : -67.138665,
      zoom : 13,
      title: 'Villa Challacollo - Zona Sud'
    },
    {
      lat : -17.948157,
      lng : -67.101680,
      zoom : 13,
      title: 'CEPROK - Zona Norte'
    },
    {
      lat : -17.976020,
      lng : -67.089968,
      zoom : 13,
      title: 'Quirquincho Feliz Y Sora - Zona Este'
    }
  ];
  faPaperPlane = faPaperPlane;
  faMapMarkerAlt = faMapMarkerAlt;
  faPhoneAlt = faPhoneAlt;
  faEnvelope = faEnvelope;
  faHome = faHome;
  contactGroup: FormGroup;
  constructor(private authService: AuthService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
    $(document).ready(() => {
      $('html, body').animate({
        scrollTop: 0
      }, 'fast');
    });
  }

  createForm() {
    this.contactGroup = this.fb.group({
      nombre: new FormControl('', [Validators.required]),
      correo: new FormControl('', [Validators.required, Validators.email]),
      asunto: new FormControl('', [Validators.required]),
      mensaje: new FormControl('', [Validators.required])
    });
  }

  sendMail()  {
    this.spinner.show();
    this.authService.sendMail(this.contactGroup.value)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.contactGroup.reset();
        this.toastr.success('Gracias por su consulta, le responderemos a la brevedad.', 'Mensaje enviado');
      });
  }

}
